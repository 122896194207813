import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { MdOutlineExplore, MdOutlineMap, MdOutlineSell } from "react-icons/md";

import routes from "../../clhbid-routes";

import { getParcelSummary, getBidSummary, getBidText } from "../helpers/sale";

import useSaleStatuses from "../hooks/use-sale-statuses";

import Button from "./Button";
import SaleDate from "./SaleDate";

const SaleCard: React.FC<{
  sale: Queries.SaleCardSaleFragment;
  className?: string;
}> = ({ sale, className = "" }) => {
  const { [sale.slug]: saleStatus } = useSaleStatuses();

  return (
    <Link
      tabIndex={-1}
      to={routes.sale.details(sale)}
      className="isolate flex flex-col rounded-md bg-clhbid-gray-dark shadow-[0_0_8px_0_rgba(0,0,0,0.2)]"
    >
      <div className={`relative ${className}`}>
        <GatsbyImage
          alt={`View from above of ${sale.name}`}
          image={sale.saleImage.gatsbyImageData}
          className="block rounded-t-md"
        />
        {sale.banner && (
          <span className="absolute bottom-0 left-0 rounded-tr-md bg-clhbid-orange px-2 py-[2px] text-sm font-bold text-clhbid-charcoal shadow-sm">
            {sale.banner}
          </span>
        )}
      </div>
      <div className="flex grow flex-col p-2">
        <div className="mb-3">
          <h4 className="truncate text-xl font-bold">{sale.name}</h4>
          <SaleDate
            showLive
            type="short"
            sale={sale}
            className="mb-0 text-clhbid-silver"
            dateClassName="text-sm font-bold"
            subtextClassName="text-xs font-normal"
            liveClassName="text-sm text-clhbid-orange font-bold italic"
          />{" "}
        </div>
        <div className="mb-2 flex items-center">
          <MdOutlineExplore className="mr-2 h-5 w-5 text-clhbid-orange" />
          <span className="text-sm font-bold leading-none">
            {sale.location} <span className="text-xs font-normal">nearby</span>
          </span>
        </div>
        <div className="mb-2 flex items-center">
          <MdOutlineSell className="mr-2 h-5 w-5 text-clhbid-orange" />
          <span className="text-sm font-bold leading-none">
            {getBidSummary(sale)}{" "}
            <span className="text-xs font-normal">
              {getBidText(sale, false, true)}
            </span>
          </span>
        </div>
        <div className="mb-4 flex items-center">
          <MdOutlineMap className="mr-2 h-5 w-5 text-clhbid-orange" />
          <span className="text-sm font-bold leading-none">
            {getParcelSummary(sale)}{" "}
            <span className="text-xs font-normal">for sale</span>
          </span>
        </div>
        <Button
          size="small"
          outline={saleStatus !== "live"}
          family="primary"
          className="mt-auto block w-full"
        >
          View Details
        </Button>
      </div>
    </Link>
  );
};

export const SaleCardFragment = graphql`
  fragment SaleCardSale on ContentfulSale {
    slug
    name
    banner
    saleStatus
    location
    saleImage {
      gatsbyImageData
    }
    parcels {
      size
      acceptedBid
      startingBid
    }
    ...SaleDateSale
  }
`;

export default SaleCard;
