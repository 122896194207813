import React from "react";

import PageSection from "./PageSection";

const CenteredPageSection: React.FC<{
  id?: string;
  classNames?: string;
  fullWidthClassNames?: string;
  children?: React.ReactNode;
}> = ({ id, classNames = "", fullWidthClassNames = "", children }) => (
  <PageSection id={id} classNames={fullWidthClassNames}>
    <div className={`w-full max-w-screen-xl px-3 py-8 ${classNames}`}>
      {children}
    </div>
  </PageSection>
);

export default CenteredPageSection;
