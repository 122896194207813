import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const TestimonialCard: React.FC<{
  testimonial: Queries.TestimonialCardTestimonialFragment;
  className?: string;
}> = ({ testimonial, className = "" }) => (
  <div className={`rounded-md p-4 ${className}`}>
    <h3 className="mb-4 text-xl font-semibold leading-6">
      {testimonial.tagline}
    </h3>
    <p className="leading-6">{testimonial.content}</p>
    <div className="flex items-center gap-2">
      {testimonial.headshot && (
        <GatsbyImage
          className="isolate block h-[50px] w-[50px] shrink-0 rounded-[25px]"
          image={testimonial.headshot.file.childImageSharp.gatsbyImageData}
          alt={testimonial.headshot.alt}
        />
      )}
      <div>
        <h3>{testimonial.name}</h3>
        <h3 className="text-sm text-clhbid-silver">
          {testimonial.title}
          {testimonial.title && testimonial.city && " - "}
          {testimonial.city}
        </h3>
      </div>
    </div>
  </div>
);

export const TestimonialCardFragment = graphql`
  fragment TestimonialCardTestimonial on TestimonialsYaml {
    title
    tagline
    headshot {
      alt
      file {
        childImageSharp {
          gatsbyImageData(width: 50, height: 50)
        }
      }
    }
    name
    content
    city
  }
`;

export default TestimonialCard;
