import React, { useState, useEffect, useRef } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { MdMenu, MdMenuOpen } from "react-icons/md";

import routes from "../../../../clhbid-routes";

import { useAuth } from "../../../concerns/auth";
import useRouteChange from "../../../hooks/use-route-change";
import useSaleStatuses from "../../../hooks/use-sale-statuses";
import { isMobileDevice } from "../../../helpers/media-queries";

import Button from "../../Button";

import Dropdown from "./Dropdown";
import { SalePanel, TestimonialPanel, LinkPanel } from "./Panels";

const MainMenu: React.FC<{
  menuOpen: boolean;
  setMenuOpen: (arg1: boolean) => void;
}> = ({ menuOpen, setMenuOpen }) => {
  const statuses = useSaleStatuses();
  const wrapper = useRef<HTMLDivElement>(null);
  const { state, register, login } = useAuth();
  const data = useStaticQuery<Queries.MainMenuQuery>(query);
  const [dropdownOpen, setDropdownOpen] = useState<Array<string>>([]);

  /**
   * This function handles the case where dropdowns are open on tablet
   * and the user clicks elsewhere by stopping the event from registering
   * and closing the menu
   */
  const closeDropdown = (event: MouseEvent) => {
    if (event instanceof MouseEvent && event.target instanceof Node) {
      if (dropdownOpen.length > 0 && !wrapper.current?.contains(event.target)) {
        event.preventDefault();
        setDropdownOpen([]);
      }
    }
  };

  /*
   * Returns a function that simplifies the opening and closing of dropdowns
   * by abstracting the fact that `openDropdown` is a complex state
   */
  const createSetOpenDropdown = (dropdown: string) => {
    return (value: boolean) => {
      // On deskton and tablet we want only a single dropdown to be open
      // while on mobile many dropdowns can be opened
      const isMobile = isMobileDevice();

      // We only handle the two case where state needs to be changed. If
      // value === true and it is already opened or if value === false and
      // it is already closed do nothing
      if (value === true && !dropdownOpen.includes(dropdown)) {
        setDropdownOpen((state) =>
          isMobile ? [...state, dropdown] : [dropdown]
        );
      } else if (value === false && dropdownOpen.includes(dropdown)) {
        setDropdownOpen((state) =>
          isMobile ? state.filter((string) => string !== dropdown) : []
        );
      }
    };
  };

  useEffect(() => {
    window.addEventListener("click", closeDropdown);

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  });

  useRouteChange(() => {
    setDropdownOpen([]);
  });

  return (
    <div className="order-1 flex lg:-order-1 lg:grow">
      <button
        className="block lg:hidden"
        onClick={() => setMenuOpen(!menuOpen)}
        aria-label={`${menuOpen ? "Open" : "Close"} main menu`}
        data-testid="main-menu-toggle"
      >
        {menuOpen ? (
          <MdMenuOpen className="h-10 w-10 rotate-180 p-1" />
        ) : (
          <MdMenu className="h-10 w-10 p-1" />
        )}
      </button>
      <div
        ref={wrapper}
        className={[
          "absolute bottom-[calc(-100vh+100%)] left-0 right-0 top-[100%] z-[-2] w-full overflow-y-auto bg-clhbid-gray p-4 duration-300 [transition-property:visibility,transform] lg:relative lg:inset-0 lg:z-auto lg:flex lg:justify-between lg:overflow-y-visible lg:p-0",
          menuOpen
            ? "translate-x-none visible lg:visible lg:transform-none"
            : "invisible translate-x-full lg:visible lg:transform-none",
        ].join(" ")}
        data-testid="main-menu-wrapper"
      >
        {Object.values(statuses).some((status) => status === "live") && (
          <div className="hidden lg:absolute lg:-top-1 lg:left-1 lg:block lg:skew-x-[-30deg] lg:rounded-sm lg:bg-clhbid-orange lg:px-2">
            <span className="block skew-x-[30deg] text-xs font-semibold uppercase text-clhbid-charcoal">
              Live
            </span>
          </div>
        )}
        <div className="flex flex-col lg:relative lg:grow lg:flex-row lg:items-center lg:gap-8 xl:pr-[252px]">
          <Dropdown
            title="Buy Land"
            link="/sales/"
            dropdownOpen={dropdownOpen.includes("buy-land")}
            setDropdownOpen={createSetOpenDropdown("buy-land")}
          >
            <SalePanel
              title="Buy Land"
              sales={data.sales.nodes}
              links={[
                {
                  path: "/sales/",
                  title: "Upcoming Sales",
                },
                {
                  path: "/past-sales/",
                  title: "Completed Sales",
                },
                {
                  path: "/questions/sale-process/how-does-the-bidding-process-work/",
                  title: "The Sales Process",
                },
                {
                  path: "/terms-and-conditions/",
                  title: "Terms and Conditions",
                },
              ]}
              button={
                state === "authenticated"
                  ? {
                      path: "/contact",
                      title: "Contact Us",
                    }
                  : {
                      path: routes.register(),
                      title: "Register To Bid",
                    }
              }
            />
          </Dropdown>
          <Dropdown
            title="Sell Land"
            link="/sell-with-clhbid/"
            dropdownOpen={dropdownOpen.includes("sell-land")}
            setDropdownOpen={createSetOpenDropdown("sell-land")}
          >
            <TestimonialPanel
              title="Sell Land"
              testimonials={data.testimonials.nodes}
              links={[
                {
                  path: "/sell-with-clhbid/",
                  title: "Sell With CLHbid.com",
                },
                {
                  path: "/clhbid-team/",
                  title: "The CLHbid.com Team",
                },
                {
                  path: "/testimonials/",
                  title: "Customer Testimonials",
                },
                {
                  path: "/faq/",
                  title: "Frequently Asked Questions",
                },
              ]}
              button={{
                path: "/contact/",
                title: "Contact Us",
              }}
            />
          </Dropdown>
          <Dropdown
            title="More"
            link="/clhbid-team/"
            dropdownOpen={dropdownOpen.includes("more")}
            setDropdownOpen={createSetOpenDropdown("more")}
          >
            <LinkPanel
              title="More"
              sections={[
                {
                  title: "Why CLHbid.com",
                  links: [
                    {
                      path: "/clhbid-team/",
                      title: "The CLHbid.com Team",
                    },
                    {
                      path: "/testimonials/",
                      title: "Customer Testimonials",
                    },
                    {
                      path: "/terms-and-conditions/",
                      title: "Terms and Conditions",
                    },
                    {
                      path: "/faq/",
                      title: "Frequently Asked Questions",
                    },
                    {
                      path: "/farmlandexchange/",
                      title: "Farmland Exchange Podcast",
                    },
                  ],
                },
                {
                  title: "How It Works",
                  links: [
                    {
                      path: "/questions/sale-process/how-does-the-bidding-process-work/#Bidding-Process",
                      title: "Bidding Process",
                    },
                    {
                      path: "/questions/sale-process/how-does-the-bidding-process-work/#Starting-Bids",
                      title: "Starting Bids",
                    },
                    {
                      path: "/questions/sale-process/how-does-the-bidding-process-work/#Closing-The-Sale",
                      title: "Closing the Sale",
                    },
                    {
                      path: "/questions/sale-process/what-is-en-bloc-and-how-does-it-work/",
                      title: "En Bloc Process",
                    },
                    {
                      path: "/questions/buying-farmland/what-is-the-transaction-fee/",
                      title: "Transaction Fee",
                    },
                  ],
                },
                {
                  title: "Compare Alternatives",
                  links: [
                    {
                      path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Private-Sale",
                      title: "Private Sale",
                    },
                    {
                      path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Public-Auction",
                      title: "Public Auction",
                    },
                    {
                      path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Private-Tender",
                      title: "Private Tender",
                    },
                    {
                      path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Professional-Realtor",
                      title: "Professional Realtor",
                    },
                  ],
                },
              ]}
            />
          </Dropdown>
          <Link
            to="/contact"
            className="block w-full border-b border-gray-300 py-4 text-xl font-semibold leading-6 hover:underline lg:w-fit lg:border-none lg:py-0 lg:text-base lg:leading-none"
          >
            Contact
          </Link>
        </div>
        {state !== "authenticated" && (
          <div className="lg:hidden">
            <button
              onClick={login}
              className="block w-full py-4 text-left text-xl font-semibold leading-6 hover:underline"
            >
              Login
            </button>
            <Button
              size="medium"
              onClick={register}
              className="mt-8 w-full whitespace-nowrap py-2"
              data-testid="register-button"
            >
              Create Account
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const query = graphql`
  query MainMenu {
    sales: allContentfulSale(
      sort: { parcels: { startTime: ASC } }
      filter: { saleStatus: { ne: "completed" } }
      limit: 2
    ) {
      nodes {
        ...SalePanelSale
      }
    }
    testimonials: allTestimonialsYaml(
      filter: { priority: { eq: true }, type: { eq: "seller" } }
    ) {
      nodes {
        ...TestimonialPanelTestimonial
      }
    }
  }
`;

export default MainMenu;
