import React from "react";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthProvider } from "../concerns/auth";
import { CookieAuth, DevAuth } from "../concerns/auth/implementations";

import config from "../../clhbid-config";

import { QueryParamsProvider } from "../hooks/use-query-params";

import Header from "./Header";
import Footer from "./Footer";
import SaleBanner from "./SaleBanner";
import WavesEffect from "./WavesEffect";
import CookieConsent from "./CookieConsent";
import ErrorBoundary from "./ErrorBoundary";
import GlobalContactForm from "./GlobalContactForm";

const authImpl = config.MOCK_AUTHENTICATION ? DevAuth : CookieAuth;

const queryClient = new QueryClient();

const Layout: React.FC<{ element: React.ReactNode }> = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider impl={authImpl}>
      <QueryParamsProvider>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <CookieConsent />
        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          position="bottom-left"
        />
        <WavesEffect />
        <Header />
        <GlobalContactForm />
        <SaleBanner />
        <div className="mx-auto flex min-h-screen max-w-screen-2xl flex-col bg-clhbid-charcoal shadow-xl">
          <main
            // We add the padding-top here to offset the header
            className="w-full flex-1 pt-[88px] lg:pt-[104px]"
          >
            <ErrorBoundary>{element}</ErrorBoundary>
          </main>
          <Footer />
        </div>
      </QueryParamsProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default Layout;
