import React from "react";
import { Link, graphql } from "gatsby";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import Button from "../../../Button";
import TestimonialCard from "../../../TestimonialCard";

const TestimonialPanel: React.FC<{
  title: string;
  testimonials: ReadonlyArray<Queries.TestimonialPanelTestimonialFragment>;
  links: Array<{ title: string; path: string }>;
  button: { title: string; path: string };
}> = ({ title, testimonials, button, links }) => (
  <div className="mb-6 grid grid-cols-1 gap-8 lg:mb-0 lg:grid-cols-[280px,560px] lg:gap-0">
    <div className="flex flex-col lg:border-r lg:border-gray-300 lg:px-8">
      <h3 className="mb-6 hidden text-xl font-bold leading-6 lg:block">
        {title}
      </h3>
      {links.map((link) => (
        <div key={link.title}>
          <Link
            key={link.title}
            to={link.path}
            className="mb-4 block w-fit font-[18px] leading-6 text-white hover:underline"
          >
            {link.title}
          </Link>
        </div>
      ))}
      <Button
        external
        size="medium"
        href={button.path}
        className="mt-4 w-full lg:mt-auto"
      >
        {button.title}
      </Button>
    </div>
    <div className="relative pb-10 lg:flex lg:items-center lg:px-8 lg:pb-0">
      <CarouselProvider
        infinite
        interval={20000}
        isPlaying={true}
        dragEnabled={false}
        touchEnabled={false}
        naturalSlideHeight={1}
        naturalSlideWidth={1.5}
        isIntrinsicHeight={true}
        totalSlides={testimonials.length}
        className="w-full"
      >
        <ButtonBack className="absolute bottom-0 left-[calc(50%-32px)] z-10 lg:bottom-auto lg:left-2 lg:top-[calc(50%-12px)]">
          <MdChevronLeft className="h-6 w-6 rounded-full text-clhbid-orange transition-colors hover:cursor-pointer hover:text-clhbid-orange-dark" />
        </ButtonBack>
        <Slider classNameTrayWrap="py-2">
          {testimonials.map((testimonial, i) => (
            <Slide
              key={testimonial.name}
              index={i}
              className="px-2"
              innerClassName="flex items-center"
            >
              <TestimonialCard
                testimonial={testimonial}
                className="bg-clhbid-gray-dark"
              />
            </Slide>
          ))}
        </Slider>
        <ButtonNext className="absolute bottom-0 right-[calc(50%-32px)] z-10 lg:bottom-auto lg:right-2 lg:top-[calc(50%-12px)]">
          <MdChevronRight className="h-6 w-6 rounded-full text-clhbid-orange transition-colors hover:cursor-pointer hover:text-clhbid-orange-dark" />
        </ButtonNext>
      </CarouselProvider>
    </div>
  </div>
);

export const TestimonialPanelFragment = graphql`
  fragment TestimonialPanelTestimonial on TestimonialsYaml {
    ...TestimonialCardTestimonial
  }
`;

export default TestimonialPanel;
