import React, { ReactNode } from "react";
import { Link } from "gatsby";

import LoadingIndicator from "./LoadingIndicator";

// The button component is polymorphic which means it renders
// different components based on the props it receives
const getComponent = (disabled: boolean, href: string, external: boolean) => {
  if (disabled || !href) return "button";
  else if (!disabled && href && external) return "a";
  else if (!disabled && href && !external) return Link;
};

export enum ButtonTargets {
  self = "_self",
  blank = "_blank",
  parent = "_parent",
  top = "_top",
}

/**
 * Button - The purpose of this component is to allow use to share styles
 * between internal links, external links and buttons that we all want to
 * appear the same in the UI. It also gives us a bunch of shared props for
 * handling different states.
 */
const Button: React.FC<{
  /** For link: either a relative or absolute URL */
  href?: string;
  /** For internal link: state to pass to the link component
   * @see https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#pass-state-as-props-to-the-linked-page
   */
  state?: Record<string, unknown>;
  /** For button: the button type */
  type?: "button" | "submit" | "reset";
  /** For all: size of the component */
  size?: "small" | "medium" | "large";
  /**
   * For all: style family for the button. Default allows you to provide your own styles */
  family?: "primary" | "secondary" | "tertiary" | "default";
  /**
   * For all: Sets whether the button has a background or a border
   */
  outline?: boolean;
  /** For all: the tabIndex of the component */
  tabIndex?: number;
  /** For all: Sets the loading state for the component */
  /** Note: This disables the component and changes the button text to "Loading..." */
  loading?: boolean;
  /** For all: Disables the component and changes the styles */
  /** Note: if the component is a link, it temporarily transforms to a button to
   * allow for the "disabled" state, because links do not have a disabled state
   */
  disabled?: boolean;
  /** For link: Whether the link is internal or external */
  external?: boolean;
  /** For all: onClick handler passed to comppnent */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /** For all: className passed to component */
  className?: string;
  /** For all: inner contents of the component */
  /** Note: This is replaced with "Loading..." when the button is in loading state. */
  children: ReactNode;
  /** When used as a link, specifies the target. This can be a window name, or special values (_self, _blank, _parent, or _top) */
  target?: ButtonTargets;
}> = ({
  href,
  state,
  type,
  size = "medium",
  family = "primary",
  outline = false,
  tabIndex,
  loading = false,
  disabled = false,
  external = false,
  onClick,
  className = "",
  children,
  target,
}) => {
  const Component = getComponent(disabled, href, external);

  return (
    <Component
      type={type}
      state={state}
      tabIndex={tabIndex}
      disabled={disabled || loading || undefined}
      to={(!disabled && !external && href) || undefined}
      href={(!disabled && external && href) || undefined}
      className={
        family === "default"
          ? className
          : `btn btn--${size} btn--${family}${outline ? "-outline" : ""}${
              disabled ? "-disabled" : ""
            } ${className}`
      }
      onClick={onClick}
      target={target}
    >
      {!loading && children}
      {loading && (
        <>
          <LoadingIndicator color="white" />
          Loading...
        </>
      )}
    </Component>
  );
};

export default Button;
