import React from "react";
import { Link, graphql } from "gatsby";
import { DateTime } from "luxon";
import { getStartTime, getEndTime } from "../helpers/sale";
import useSaleStatuses from "../hooks/use-sale-statuses";

enum PropType {
  Sale = "sale",
  Parcel = "parcel",
  Option = "option",
  Feature = "feature",
}

interface BaseFragment {
  objectType: PropType;
}
interface SaleFragment extends BaseFragment, Queries.SaleDateSaleFragment {
  objectType: PropType.Sale;
}
interface ParcelFragment extends BaseFragment, Queries.SaleDateParcelFragment {
  objectType: PropType.Parcel;
}
interface OptionFragment extends BaseFragment, Queries.SaleDateOptionFragment {
  objectType: PropType.Option;
}
interface FeatureFragment
  extends BaseFragment,
    Queries.SaleDateFeatureFragment {
  objectType: PropType.Feature;
}
type ObjectFragment =
  | SaleFragment
  | ParcelFragment
  | OptionFragment
  | FeatureFragment;

const objectPropertyMap = {
  sale: {
    sale: (object) => object,
    parcel: (object: Queries.SaleDateSaleFragment) =>
      object.parcels.find((parcel) => !parcel.enBloc),
  },
  parcel: {
    sale: (object: Queries.SaleDateParcelFragment) => object.sale[0],
    parcel: (object) => object,
  },
  option: {
    sale: (object: Queries.SaleDateParcelFragment) => object.sale[0],
    parcel: (object) => object,
  },
  feature: {
    sale: (object: Queries.SaleDateFeatureFragment) =>
      object.parcel.find((parcel) => !parcel.enBloc).sale[0],
    parcel: (object: Queries.SaleDateFeatureFragment) =>
      object.parcel.find((parcel) => !parcel.enBloc),
  },
} as Record<
  PropType,
  {
    sale: (object: ObjectFragment) => SaleFragment;
    parcel: (object: ObjectFragment) => ParcelFragment;
  }
>;

/** Given the props passed into this component, determine the type of object we're trying to render. */
function getPropsType(props: SaleDateProps): PropType {
  if ("sale" in props) return PropType.Sale;
  if ("parcel" in props) return PropType.Parcel;
  if ("option" in props) return PropType.Option;
  if ("feature" in props) return PropType.Feature;
}

/**
 * Given a set of props and the type of object we're displaying, return an object with methods for determining the sale
 * and parcel associated with the object.
 * */
function getObject(props: SaleDateProps, objectType: PropType): ObjectFragment {
  const object = props[objectType] as ObjectFragment;
  // Set the object type so we can tell the type of object apart later using a discriminated union
  object.objectType = objectType;
  return object;
}

/** Returns true if an object is an OptionFragment. This is used for narrowing the type when rendering. */
function isOptionFragment(object: ObjectFragment): object is OptionFragment {
  return object.objectType === "option";
}

type BaseSaleDateProps = {
  showLive?: boolean;
  className?: string;
  showPrefix?: boolean;
  liveClassName?: string;
  dateClassName?: string;
  prefixClassName?: string;
  subtextClassName?: string;
  [key: string]: unknown;
};
type SaleSaleDateProps = BaseSaleDateProps & {
  type: "short" | "long";
  sale: Queries.SaleDateSaleFragment;
};
type ParcelSaleDateProps = BaseSaleDateProps & {
  type: "short" | "long";
  parcel: Queries.SaleDateParcelFragment;
};
type OptionSaleDataProps = BaseSaleDateProps & {
  type: undefined;
  option: Queries.SaleDateOptionFragment;
};
type FeatureSaleDateProps = BaseSaleDateProps & {
  type: "short" | "long";
  feature: Queries.SaleDateFeatureFragment;
};

type SaleDateProps =
  | SaleSaleDateProps
  | ParcelSaleDateProps
  | OptionSaleDataProps
  | FeatureSaleDateProps;

const SaleDate: React.FC<SaleDateProps> = (props) => {
  const {
    type,
    showLive = false,
    showPrefix = true,
    className = "",
    liveClassName = "",
    dateClassName = "",
    prefixClassName = "",
    subtextClassName = "",
  } = props;

  const objectType = getPropsType(props);
  const object = getObject(props, objectType);
  const sale = objectPropertyMap[objectType].sale(object);

  const { [sale.slug]: saleStatus } = useSaleStatuses();

  if (isOptionFragment(object)) {
    const exerciseDate = DateTime.fromISO(object.exerciseDate);

    return (
      <p className={className}>
        <span className={prefixClassName}>Exercise</span>
        <span className={subtextClassName}> by </span>
        <span className={dateClassName}>
          {exerciseDate.toFormat("MMMM d',' yyyy")}
        </span>
        <br className="md:hidden" />
        <span className={subtextClassName}> at </span>
        <span className={dateClassName}>{exerciseDate.toFormat("h:mm")}</span>
        <span className={subtextClassName}>
          {exerciseDate.toFormat(" a ZZZZ")}
        </span>
      </p>
    );
  }

  const parcel = objectPropertyMap[objectType].parcel(object);

  const startTime = DateTime.fromISO(
    objectType === "sale" ? getStartTime(sale) : parcel.startTime
  );

  const endTime = DateTime.fromISO(
    objectType === "sale" ? getEndTime(sale) : parcel.endTime
  );

  if (saleStatus === "live" && showLive) {
    return (
      <p className={className} data-testid="sale-date">
        <span className="inline-flex items-center">
          <span className="relative ml-[2px] mr-2 flex h-3 w-3">
            <span className="absolute inline-flex h-3 w-3 animate-live-ping rounded-full bg-gray-700 opacity-75"></span>
            <span className="relative inline-flex h-3 w-3 animate-live-dot rounded-full bg-gray-700"></span>
          </span>
          <span className={liveClassName}>SELLING NOW!</span>
        </span>
      </p>
    );
  }

  if (type === "short") {
    return (
      <p className={className} data-testid="sale-date">
        <span className={dateClassName}>
          {startTime.toFormat("MMM d',' yyyy")}
        </span>
        <span className={subtextClassName}> at </span>
        <span className={dateClassName}>{startTime.toFormat("h:mm")}</span>
        <span className={subtextClassName}>
          {" "}
          {startTime.toFormat("a ZZZZ")}
        </span>
      </p>
    );
  }

  if (type === "long") {
    return (
      <div className={className}>
        <p className="m-0" data-testid="sale-date">
          {showPrefix && (
            <>
              <span className={prefixClassName}>
                {saleStatus === "completed" ? "Sold" : "Selling"}
              </span>
              <span className={subtextClassName}> on </span>
            </>
          )}
          <span className={dateClassName}>
            {startTime.toFormat("MMMM d',' yyyy")}
          </span>
          {!parcel.enBloc && (
            <>
              {showPrefix && <br className="md:hidden" />}
              <span className={subtextClassName}> from </span>
              <span className={dateClassName}>
                {startTime.toFormat("h:mm")}
              </span>
              <span className={subtextClassName}>
                {` ${startTime.toFormat("a")} to `}
              </span>
              <span className={dateClassName}>{endTime.toFormat("h:mm")}</span>
              <span className={subtextClassName}>
                {` ${endTime.toFormat("a ZZZZ")}`}
              </span>
            </>
          )}
        </p>
        {parcel.enBloc && saleStatus !== "completed" && (
          <p
            className="mb-0 mt-2 text-clhbid-silver"
            data-testid="enbloc-description"
          >
            {`En Bloc starts 15 minutes after normal bidding closes for eligible bidders. `}
            <Link
              to="/how-clhbid-works/bidding-on-en-bloc-properties-on-clhbid"
              className="whitespace-nowrap font-medium text-clhbid-orange"
            >
              Learn More.
            </Link>
          </p>
        )}
      </div>
    );
  }
};

export const SaleDateFragment = graphql`
  fragment SaleDateSale on ContentfulSale {
    slug
    parcels {
      startTime
      endTime
      enBloc
    }
  }

  fragment SaleDateParcel on ContentfulParcel {
    startTime
    endTime
    enBloc
    sale {
      slug
    }
  }

  fragment SaleDateOption on ContentfulOption {
    exerciseDate
    sale {
      slug
    }
  }

  fragment SaleDateFeature on ContentfulFeature {
    parcel {
      startTime
      endTime
      enBloc
      sale {
        slug
      }
    }
  }
`;

export default SaleDate;
