import React, { useRef, useEffect, useState } from "react";
import { MdSearch, MdClose } from "react-icons/md";
import { navigate } from "gatsby";
import { Combobox } from "@headlessui/react";

import { getSuggestionGroups } from "../../../helpers/get-suggestion-groups";

import useRouteChange from "../../../hooks/use-route-change";
import useSearchSuggestions from "../../../hooks/use-search-suggestions";

type SearchOption =
  | {
      type: "location";
      key: string;
      label: string;
      coordinates: [number, number];
    }
  | {
      type: "sale";
      key: string;
      slug: string;
      name: string;
      location: string;
    }
  | {
      type: "query";
      key: string;
      value: string;
    };

const SearchBar: React.FC = () => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const input = useRef<HTMLInputElement>();
  const wrapper = useRef<HTMLDivElement>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const types = { locations: true, sales: true, query: true };

  const { status, locations, sales } = useSearchSuggestions(value, types);

  /**
   * It isn't possible to focus the input as soon as the open status is changed because the
   * visibility is still set to hidden. To solve this problem and avoid flickering we wait until
   * the animation finishes before focusing the element.
   */
  useEffect(() => {
    clearTimeout(timeout.current);

    if (open) {
      timeout.current = setTimeout(() => {
        if (input.current) {
          input.current.focus();
        }
      }, 250);
    }
  }, [open]);

  const groups = getSuggestionGroups({
    locations,
    sales,
    value,
    types,
  });

  const optionCount = groups.reduce(
    (options, group) => (options += group.options.length),
    0
  );

  useEffect(() => {
    if (open === false) {
      setValue("");
    }
  }, [open]);

  useRouteChange(() => {
    setOpen(false);
  });

  const handleChange = (option: SearchOption | null) => {
    input.current.blur();
    setOpen(false);

    if (option?.type === "location") {
      navigate(
        `/sales?${new URLSearchParams({
          coordinates: option.coordinates.join("_"),
          label: option.label,
        })}`
      );
    } else if (option?.type === "sale") {
      navigate(`/auctions/${option.slug}`);
    } else if (option?.type === "query") {
      navigate(
        `/search?${new URLSearchParams({
          query: option.value,
        })}`
      );
    } else {
      navigate("/sales");
    }
  };

  return (
    <div>
      <button
        id="search-bar-button"
        className="block xl:hidden"
        onClick={() => {
          setOpen(true);
        }}
        aria-label={`${open ? "Close" : "Open"} search bar`}
        data-testid="search-bar-toggle"
      >
        <MdSearch className="h-10 w-10 fill-clhbid-orange p-2 lg:h-[44px] lg:w-[44px] lg:p-[10px]" />
      </button>
      <div
        ref={wrapper}
        data-testid="search-bar-form"
        onBlur={({ relatedTarget }) => {
          /**
           * relatedTarget is the element getting focus. This logic allows us to check if the
           * element we are switiching focus to is not inside the div, in which case we close the
           * meny. This prevents us from closing the menu when focusing the clear or search buttons
           * instead of the input.
           */
          if (!wrapper.current.contains(relatedTarget)) {
            setOpen(false);
          }
        }}
        className={[
          "fixed left-0 right-0 top-[28px] z-10 mx-4 my-2 bg-clhbid-gray duration-200 [transition-property:visibility,opacity] lg:absolute lg:top-0 lg:mx-0 lg:my-0 lg:bg-transparent xl:ml-auto xl:[transition-property:width]",
          open
            ? "visible opacity-100 lg:w-full"
            : "invisible opacity-0 xl:visible xl:w-[220px] xl:opacity-100 xl:delay-200",
        ].join(" ")}
      >
        <Combobox onChange={handleChange}>
          {({ activeOption }) => (
            <>
              <Combobox.Input
                id="header-search"
                ref={input}
                onFocus={() => {
                  setOpen(true);
                }}
                value={value}
                onChange={({ target: { value: newValue } }) => {
                  setValue(newValue);
                }}
                placeholder={
                  open ? "Search for land near you" : "Search for land"
                }
                className={[
                  "block w-full rounded-md border-none bg-clhbid-gray-dark p-[11px] pr-[76px] text-lg leading-[22px] placeholder:text-clhbid-silver",
                  open ? "lg:pr-[76px]" : "lg:pr-[44px]",
                ].join(" ")}
              />
              <div className="absolute right-0 top-0 p-[6px]">
                <button
                  type="button"
                  onFocus={() => {
                    if (!open) {
                      setOpen(true);
                    }
                  }}
                  onClick={() => {
                    setOpen(false);
                    setValue("");
                  }}
                  aria-label="clear search"
                  className={[
                    "inline-block p-1 text-clhbid-orange hover:text-clhbid-orange-dark lg:cursor-pointer lg:transition-colors",
                    open ? "" : "xl:hidden",
                  ].join(" ")}
                >
                  <MdClose className="h-6 w-6" />
                </button>
                <button
                  onFocus={() => {
                    if (!open) {
                      setOpen(true);
                    }
                  }}
                  onClick={() => {
                    handleChange(activeOption);
                  }}
                  aria-label="submit search"
                  className="inline-block p-1 text-clhbid-orange transition-colors hover:text-clhbid-orange-dark lg:cursor-pointer"
                >
                  <MdSearch className="h-6 w-6" />
                </button>
              </div>
              <Combobox.Options
                static
                className={[
                  "z-100 absolute left-0 right-0 top-[60px] rounded-md bg-clhbid-gray-dark p-4 shadow-[0_0_8px_0_rgba(0,0,0,0.4)] lg:top-[68px] xl:duration-200 xl:[transition-property:visibility,opacity]",
                  open
                    ? "xl:opacity-100 xl:delay-200"
                    : "xl:invisible xl:opacity-0",
                ].join(" ")}
              >
                {status !== "error" ? (
                  groups.map((group) => {
                    if (
                      Array.isArray(group.options) &&
                      group.options.length > 0
                    ) {
                      return (
                        <React.Fragment key={group.type}>
                          {optionCount > 1 && (
                            <div
                              className={["mb-2 flex items-center gap-4"].join(
                                " "
                              )}
                            >
                              {group.name && (
                                <h3 className="font-bold text-clhbid-silver">
                                  {group.name}
                                </h3>
                              )}
                              <hr
                                className={[
                                  "grow border-clhbid-gray-light",
                                  group.name ? "" : "my-1",
                                ].join(" ")}
                              />
                            </div>
                          )}
                          {group.options.map((option) => {
                            const active =
                              activeOption && activeOption.key === option.key;

                            return (
                              <Combobox.Option
                                key={option.key}
                                value={option}
                                className={[
                                  "group mb-1 flex cursor-pointer items-center rounded-md p-1 leading-5 last:mb-0",
                                  active
                                    ? "bg-clhbid-orange text-clhbid-gray"
                                    : "text-white",
                                ].join(" ")}
                              >
                                <group.Icon
                                  className={[
                                    "mr-2 h-6 w-6 flex-shrink-0",
                                    active
                                      ? "fill-clhbid-gray"
                                      : "fill-clhbid-orange",
                                  ].join(" ")}
                                />
                                {option.text}
                              </Combobox.Option>
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  })
                ) : (
                  <p className="mx-auto my-4 max-w-[600px] text-center leading-6">
                    An error occurred while attempting to load search
                    suggestions. Please try again or{" "}
                    <a
                      className="text-clhbid-orange hover:text-clhbid-orange-dark"
                      href="mailto: info@clhbid.com"
                    >
                      contact CLHbid
                    </a>
                    .
                  </p>
                )}
              </Combobox.Options>
            </>
          )}
        </Combobox>
      </div>
    </div>
  );
};

export default SearchBar;
