import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import routes from "../../clhbid-routes";

import useSaleStatuses from "../hooks/use-sale-statuses";

import Button from "./Button";

const SaleBanner: React.FC = () => {
  const data = useStaticQuery<Queries.SaleBannerQuery>(query);
  const statuses = useSaleStatuses();

  const liveSales = data.sales.nodes.filter(
    (sale) => statuses[sale.slug] === "live"
  );

  if (liveSales.length === 0) {
    return null;
  }

  return (
    <div className="fixed bottom-0 right-0 z-[49] flex w-full max-w-full items-center space-x-8 rounded-tl-xl bg-clhbid-gray px-4 py-3 shadow-[0_0_8px_0_rgba(0,0,0,0.4)] sm:w-fit">
      <div className="min-w-0 flex-1">
        <h3 className="mb-1 truncate text-xl font-bold text-white">
          {liveSales.length === 1
            ? liveSales[0].name
            : `${liveSales.length} Sales`}
        </h3>
        <div data-testid="indicator" className="flex items-center">
          <span className="relative mr-[6px] flex h-3 w-3">
            <span className="absolute inline-flex h-3 w-3 animate-live-ping rounded-full bg-clhbid-gray opacity-75"></span>
            <span className="relative inline-flex h-3 w-3 animate-live-dot rounded-full bg-clhbid-gray"></span>
          </span>
          <span className="block font-bold italic leading-4 text-clhbid-orange">
            SELLING NOW!
          </span>
        </div>
      </div>
      <Button
        href={
          liveSales.length === 1 ? routes.sale.bidding(liveSales[0]) : "/sales"
        }
        size="medium"
        external
      >
        {liveSales.length === 1 ? "View Bidding" : "View Sales"}
      </Button>
    </div>
  );
};

const query = graphql`
  query SaleBanner {
    sales: allContentfulSale {
      nodes {
        slug
        name
      }
    }
  }
`;

export default SaleBanner;
