import React from "react";
import { Link, graphql } from "gatsby";

import Button from "../../../Button";
import SaleCard from "../../../SaleCard";

const SalePanel: React.FC<{
  title: string;
  sales: ReadonlyArray<Queries.SalePanelSaleFragment>;
  button: { title: string; path: string };
  links: Array<{ title: string; path: string }>;
}> = ({ title, sales, button, links }) => (
  <div className="mb-6 grid grid-cols-1 gap-8 lg:mb-0 lg:grid-cols-[280px,560px] lg:gap-0">
    <div className="flex flex-col lg:border-r lg:border-gray-300 lg:px-8">
      <h3 className="mb-6 hidden text-xl font-bold leading-6 lg:block">
        {title}
      </h3>
      {links.map((link) => (
        <Link
          key={link.title}
          to={link.path}
          className="mb-4 block w-fit font-[18px] leading-6 text-white hover:underline"
        >
          {link.title}
        </Link>
      ))}
      <Button
        external
        size="medium"
        href={button.path}
        className="mt-4 w-full lg:mt-auto"
      >
        {button.title}
      </Button>
    </div>
    <div className="overflow-x-auto lg:overflow-visible">
      {sales.length > 0 ? (
        <div className="grid grid-cols-[232px,232px] gap-8 lg:px-8">
          {sales.map((sale) => (
            <SaleCard key={sale.id} sale={sale} />
          ))}
        </div>
      ) : (
        <div className="flex h-full items-center justify-center lg:px-8">
          <p className="mb-0 max-w-[340px] text-center">
            It looks like there aren&apos;t any upcoming sales. Check back later
            for more information!
          </p>
        </div>
      )}
    </div>
  </div>
);

export const SalePanelFragment = graphql`
  fragment SalePanelSale on ContentfulSale {
    id
    ...SaleCardSale
  }
`;

export default SalePanel;
