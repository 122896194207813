import React, { useRef, useState, useEffect } from "react";
import { MdClose } from "react-icons/md";

import Button from "./Button";
import MiniContactForm from "./MiniContactForm";

const GlobalContactForm: React.FC = () => {
  const wrapper = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (open && wrapper.current && event.target instanceof Node) {
        if (!wrapper.current.contains(event.target)) {
          setOpen(false);
        }
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [open]);

  const onContactUsButtonClick = () => {
    // Trigger custom analytics events for tracking form effectiveness
    window?.gtag?.("event", "globalcontactform_start", {
      event_label: "Open the Global Contact Form (Contact Us side button)",
    });
    window?.posthog?.capture("GlobalContactForm.Opened");

    setOpen(true);
  };

  return (
    <div ref={wrapper}>
      <div
        className={[
          "duration-400 fixed bottom-[calc(50%-64px)] right-0 z-50 [transition-property:visibility,transform]",
          !open
            ? "visible translate-x-0 lg:translate-x-0 lg:opacity-100"
            : "invisible translate-x-full lg:translate-x-0 lg:opacity-0",
        ].join(" ")}
      >
        <Button
          family="primary"
          onClick={onContactUsButtonClick}
          className="rounded-r-none px-[6px] py-2 shadow-[0px_4px_16px_0_rgba(0,0,0,0.6)]"
        >
          <span className="block w-5 [writing-mode:vertical-lr]">
            Contact Us
          </span>
        </Button>
      </div>
      <div
        className={[
          "fixed bottom-0 right-0 z-50 ml-auto w-[472px] max-w-full p-4 duration-300 [transition-property:visibility,transform]",
          open ? "visible animate-slide-in" : "invisible animate-slide-out",
        ].join(" ")}
      >
        <div className="max-h-[calc(100vh-32px)] overflow-y-auto rounded-md bg-clhbid-gray-dark p-4 shadow-[0px_4px_16px_0_rgba(0,0,0,0.6)]">
          <h3 className="relative mb-3 text-center text-2xl font-semibold">
            Contact CLHbid.com
            <button
              onClick={() => setOpen(false)}
              className="absolute right-0 h-6 w-6"
            >
              <MdClose />
            </button>
          </h3>
          <div className="overflow-y-auto">
            <p className="text-center">
              Note that we may take up to a few hours to respond by email. If
              you require immediate assistance call us at{" "}
              <a
                className="text-clhbid-orange hover:text-clhbid-orange-dark"
                href="tel:1-866-263-7480"
              >
                +1 (866) 263-7480
              </a>
              .
            </p>
          </div>
          <MiniContactForm />
        </div>
      </div>
    </div>
  );
};

export default GlobalContactForm;
