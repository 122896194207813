import config from "./clhbid-config";
import slugify from "slugify";

export default {
  login: (): string => `${config.AUCTION_ENGINE_URL}/login/`,
  logout: (): string => `${config.AUCTION_ENGINE_URL}/logout/`,
  register: (): string => `${config.AUCTION_ENGINE_URL}/register/`,
  profile: (): string => `${config.AUCTION_ENGINE_URL}/profile/`,
  admin_sales: (): string => `${config.AUCTION_ENGINE_URL}/admin/auctions/`,
  admin_users: (): string => `${config.AUCTION_ENGINE_URL}/admin/users/`,
  article: {
    category: ({ category }: { category: string }): string =>
      `/faq?category=${slugify(category)}`,
    details: ({ category, slug }: { category: string; slug: string }): string =>
      `/questions/${slugify(category)}/${slug}`,
  },
  sale: {
    details: (
      { slug }: { slug: string },
      { useAbsoluteUrl }: { useAbsoluteUrl: boolean } = {
        useAbsoluteUrl: false,
      }
    ): string =>
      useAbsoluteUrl
        ? `${config.SALES_URL}/auctions/${slug}/`
        : `/auctions/${slug}`,
    reminder: ({ slug }: { slug: string }): string =>
      `/auctions/${slug}/reminder/`,
    bidding: ({ slug }: { slug: string }): string =>
      `${config.AUCTION_ENGINE_URL}/auctions/${slug}/parcels/`,
  },
  parcel: {
    details: ({
      slug,
      sale,
    }: {
      slug: string;
      sale: readonly { slug: string }[];
    }): string => `/auctions/${sale[0].slug}/parcels/${slug}/`,
  },
  option: {
    details: ({
      slug,
      sale,
    }: {
      slug: string;
      sale: readonly { slug: string }[];
    }): string => `/auctions/${sale[0].slug}/options/${slug}/`,
  },
  feature: {
    details: ({
      slug,
      parcel,
    }: {
      slug: string;
      parcel: readonly { slug: string; sale: readonly { slug: string }[] }[];
    }): string =>
      `/auctions/${parcel[0].sale[0].slug}/parcels/${parcel[0].slug}/features/${slug}/`,
  },
  teamMember: ({ slug }: { slug: string }): string => `/clhbid-team/${slug}/`,
  podcastEpisode: ({
    title,
    itunes: { episode },
  }: {
    title: string;
    itunes: { episode: string };
  }): string =>
    `/farmlandexchange/episodes/${episode}-${slugify(title.toLowerCase())}/`,
  relativeToAbsolute: (path: string): string => `${config.SALES_URL}${path}`,
};
