import formatCurrency from "./format-currency";

export type BidType = "acceptedBid" | "startingBid";

/** Used to distinguish different object types when rendering cards, sale item lists, etc. */
export enum DetailTypeEnum {
  Parcel = "parcel",
  EnBloc = "enBloc",
  Option = "option",
  Feature = "feature",
}

export function getStartTime(sale: {
  parcels: ReadonlyArray<{ enBloc: boolean; startTime: string }>;
}): string {
  return sale.parcels.reduce(
    (acc, parcel) =>
      !parcel.enBloc && new Date(parcel.startTime) < new Date(acc)
        ? parcel.startTime
        : acc,
    sale.parcels.find((parcel) => !parcel.enBloc).startTime
  );
}

export function getEndTime(sale: {
  parcels: ReadonlyArray<{ enBloc: boolean; endTime: string }>;
}): string {
  return sale.parcels.reduce(
    (acc, parcel) =>
      !parcel.enBloc && new Date(parcel.endTime) > new Date(acc)
        ? parcel.endTime
        : acc,
    sale.parcels.find((parcel) => !parcel.enBloc).endTime
  );
}

export function getParcelSummary(sale: {
  parcels: ReadonlyArray<{ enBloc: boolean; size: string }>;
}): string {
  const enBlocCount = sale.parcels.filter((parcel) => parcel.enBloc).length;
  const parcelCount = sale.parcels.filter((parcel) => !parcel.enBloc).length;

  let summary = `${parcelCount} Parcel${parcelCount > 1 ? "s" : ""}`;

  if (enBlocCount > 0) {
    summary += `, ${enBlocCount} En Bloc${enBlocCount > 1 ? "s" : ""}`;
  } else if (sale.parcels.length === 1 && sale.parcels[0].size) {
    summary += `, ${sale.parcels[0].size}`;
  }

  return summary;
}

export function getBidType({
  saleStatus,
  parcels,
}: {
  saleStatus: string;
  parcels: ReadonlyArray<{ acceptedBid: number }>;
}): BidType {
  if (
    saleStatus === "completed" &&
    parcels.some((parcel) => parcel.acceptedBid)
  ) {
    return "acceptedBid";
  }

  return "startingBid";
}

export interface GetBidTextSale {
  saleStatus: string;
  parcels: ReadonlyArray<{ acceptedBid: number }>;
}
export function getBidText(
  sale: GetBidTextSale,
  capitalize: boolean,
  pluralize: boolean
) {
  let text = "";
  const bidType = getBidType(sale);

  if (bidType === "startingBid") {
    text += capitalize ? "Starting Bid" : "starting bid";
    if (pluralize && sale.parcels.length > 1) text += "s";
  } else {
    text += capitalize ? "Final" : "final";
  }

  return text;
}

export function getBidSummary(
  sale: {
    saleStatus: string;
    parcels: ReadonlyArray<{
      enBloc: boolean;
      startingBid: number;
      acceptedBid: number;
    }>;
  },
  options: { singleParcelCompact?: boolean } = { singleParcelCompact: false }
): string {
  const bidType = getBidType(sale);

  if (sale.parcels.length === 1) {
    return formatCurrency(
      sale.parcels[0][bidType] || 0,
      options.singleParcelCompact ? "compact" : "standard"
    );
  }

  const lowestStartingBid = formatCurrency(
    sale.parcels.reduce(
      (acc, parcel) =>
        !parcel.enBloc && (parcel[bidType] || 0) < acc
          ? parcel[bidType] || 0
          : acc,
      sale.parcels.find((parcel) => !parcel.enBloc && parcel[bidType])?.[
        bidType
      ]
    ) || 0,
    "compact"
  );

  const highestStartingBid = formatCurrency(
    sale.parcels.reduce(
      (acc, parcel) =>
        !parcel.enBloc && (parcel[bidType] || 0) > acc
          ? parcel[bidType] || 0
          : acc,
      sale.parcels.find((parcel) => !parcel.enBloc && parcel[bidType])?.[
        bidType
      ]
    ) || 0,
    "compact"
  );

  return `${lowestStartingBid} - ${highestStartingBid}`;
}

export function getSaleCountdown(
  startTime: number,
  currentTime: number
): string {
  let remainingTime = startTime - currentTime;
  if (remainingTime < 0) remainingTime = 0;

  const countdown = {
    d: Math.floor(remainingTime / (1000 * 60 * 60 * 24)),
    h: Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    m: Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)),
    s: Math.floor((remainingTime % (1000 * 60)) / 1000),
  };

  return Object.entries(countdown).reduce((current, [name, value]) => {
    // If the time is more than one day then don't show seconds
    if (countdown.d !== 0 && name === "s") {
      return current;
    }

    // Hide days and hours if they are empty
    if (["d", "h"].includes(name) && current.length === 0 && value === 0) {
      return current;
    }

    // Always show the seconds as two digits
    if (name === "s" && value < 10) {
      return current + ` : 0${value}s`;
    }

    return current + `${current.length > 0 ? " : " : ""}${value}${name}`;
  }, "");
}
