import React from "react";
import { Link } from "gatsby";

const LinkPanel: React.FC<{
  title: string;
  sections: Array<{
    title: string;
    links: Array<{ title: string; path: string }>;
  }>;
}> = ({ sections }) => {
  return (
    <div className="lg:grid lg:grid-cols-[repeat(3,280px)]">
      {sections.map((section) => (
        <div
          key={section.title}
          className="mb-8 pl-4 lg:my-0 lg:border-r lg:border-gray-300 lg:px-8 lg:last:border-r-0"
        >
          <h3 className="mb-4 text-xl font-bold leading-6 lg:mb-6">
            {section.title}
          </h3>
          {section.links.map((link) => (
            <Link
              key={link.title}
              to={link.path}
              className="mb-4 block w-fit font-[18px] leading-6 text-white hover:underline"
            >
              {link.title}
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default LinkPanel;
